<template>
  <el-dialog
    class="el-dialog-verify-order"
    width="98%"
    title="Order Verification"
    :visible="dialogVerifyOrder"
    @close="handleVerifyOrderDialogClose"
    @open="handleVerifyOrderDialogOpen"
  >
    <el-form class="el-form-verify-order" :model="orderVerificationForm" ref="orderVerificationForm" :validate-on-rule-change="false">
      <h1 class="margin-0">Product</h1>
      <div class="div-product-body">
        <el-table ref="verifyProductTable" v-loading="loading" row-key="id" :data="orderVerificationForm.orderVerificationProductTableData" height="100%" @selection-change="handleProductSelectionChange">
          <el-table-column key="checkbox" type="selection" min-width="55"/>
          <el-table-column label="Product Name" prop="sysProductName" show-overflow-tooltip min-width="200px"/>
          <el-table-column label="Barge Name" min-width="200px">
            <template v-slot="scope">
              <div v-if="scope.row.jobInfo.selectedJobs.length > 0">
                  <p class="margin-0" v-for="item in scope.row.jobInfo.selectedJobs" :key="item.jobId">{{ item.bargeName }}</p>
                </div>
                <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column v-if="orderType !== ORDER_TYPE.EX_WHARF" min-width="200px">
            <template v-slot:header><span>* </span>eBDN No</template>
            <template v-slot="scope">
              <el-form-item :prop="`orderVerificationProductTableData.${scope.$index}.jobInfo.selectedJobs`" :rules="rules.reqRule">
                <el-select v-model="scope.row.jobInfo.selectedJobs" multiple placeholder="select" value-key="jobId" @change="handleSelectedJobsChange(scope.row.jobInfo.selectedJobs, scope.$index)">
                  <el-option
                    v-for="item in scope.row.jobInfo.jobOptions"
                    :key="item.jobId"
                    :label="item.docNumber"
                    :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column v-else label="CQ Number" width="230">
            <template slot-scope="scope">
              <el-select v-if="scope.row.jobInfo.selectedJobs.length > 0" v-model="scope.row.jobInfo.selectedJobs" multiple placeholder="select" value-key="jobId" disabled>
                <el-option
                  v-for="item in scope.row.jobInfo.jobOptions"
                  :key="item.jobId"
                  :label="item.docNumber"
                  :value="item">
                </el-option>
              </el-select>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column v-if="orderType !== ORDER_TYPE.EX_WHARF" min-width="200px">
            <template v-slot:header><span>* </span>eBDN Date</template>
            <template v-slot="scope">
              <div>
                <el-form-item :prop="`orderVerificationProductTableData.${scope.$index}.supplyDate`" :rules="rules.reqRule">
                  <el-select v-model="scope.row.supplyDate">
                    <el-option
                      v-for="item in scope.row.jobInfo.ebdnDates"
                      :key="item"
                      :label="item"
                      :value="item.replace(/\s*\(.*?\)\s*/g, '')">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </template>
          </el-table-column>
          <el-table-column v-else label="CQ Date" width="200">
            <template slot-scope="scope">
              <div v-if="scope.row.jobInfo.selectedJobs.length > 0">
                <p v-for="item in scope.row.jobInfo.selectedJobs" :key="item.jobId">
                  <i class="el-icon-time"></i>
                  <span>{{ item.docDate }}</span>
                </p>
              </div>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="Min Qty" min-width="150px">
            <template v-slot="scope">
            <p class="margin-0 margin-btm-22">{{numberWithCommas(scope.row.minimumQuantity, 3)}}</p>
            </template>
          </el-table-column>
          <el-table-column prop="" label="Max Qty" min-width="150px">
            <template v-slot="scope">
            <p class="margin-0 margin-btm-22">{{scope.row.maximumQuantity ? numberWithCommas(scope.row.maximumQuantity, 3) : '-'}}</p>
            </template>
          </el-table-column>
          <el-table-column prop="" label="Final Qty" min-width="150px">
            <template v-slot="scope">
            <p class="margin-0 margin-btm-22">{{scope.row.finalQuantity ? numberWithCommas(scope.row.finalQuantity, 3): '-'}}</p>
            </template>
          </el-table-column>
          <el-table-column prop="verifiedQuantity" min-width="200px">
            <template v-slot:header><span>* </span>Delivered Quantity</template>
            <template v-slot="scope">
              <el-form-item :prop="`orderVerificationProductTableData.${scope.$index}.verifiedQuantity`" :rules="rules.reqRule">
                <el-input-number :controls="false"  :precision="3" :min="null" v-model="scope.row.verifiedQuantity"/>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column v-if="orderType !== ORDER_TYPE.EX_WHARF" prop="bdnPdfId" label="BDN Document" min-width="150px">
            <template v-slot="scope">
              <div v-if="scope.row.jobInfo.selectedJobs.length > 0" class="margin-btm-22">
                <div v-for="item in scope.row.jobInfo.selectedJobs" :key="item.jobId">
                  <el-button type="primary" plain @click.prevent="previewFile(item.bdnPdfId)">{{ item.docNumber }}</el-button>
                </div>
              </div>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column v-else label="Loading Documents" width="200">
            <template slot-scope="scope">
              <div v-if="scope.row.jobInfo.selectedJobs.length > 0">
                <div v-for="item in scope.row.jobInfo.selectedJobs" :key="item.jobId">
                  <el-button type="primary" plain @click="previewFile(item.cqPdfId)">{{`[${item.docNumber}] CQ File`}}</el-button>
                  <el-button type="primary" plain @click="previewFile(item.coqPdfId)" style="margin: 0">{{`[${item.docNumber}] COQ File`}}</el-button>
                </div>
              </div>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="Price" min-width="150px">
            <template v-slot="scope">
            <p class="margin-0 margin-btm-22">${{numberWithCommas(scope.row.price, 3)}}</p>
            </template>
          </el-table-column>
          <el-table-column v-if="!(order && order.termContract && order.termContract.termContractType === 'TIME_CHARTER')" prop="finalBillingPrice" min-width="200px">
            <template v-slot:header><span>* </span>Final Billing Price</template>
            <template v-slot="scope">
              <el-form-item :prop="`orderVerificationProductTableData.${scope.$index}.finalBillingPrice`" :rules="rules.reqRule">
                <el-input-number :controls="false" :precision="3" :min="null" v-model="scope.row.finalBillingPrice"/>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="currency" min-width="150px">
            <template v-slot:header><span>* </span>Currency</template>
            <template v-slot="scope">
              <el-form-item :prop="`orderVerificationProductTableData.${scope.$index}.currency`" :rules="rules.reqRule">
                <el-select
                  v-model="scope.row.currency"
                  disabled
                >
                  <el-option
                    v-for="item in currencyList"
                    :key="item.id" :label="item.code" :value="item.code">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column v-if="!(order && order.termContract && order.termContract.termContractType === 'TIME_CHARTER')" min-width="200px">
            <template v-slot:header><span>* </span>Price Type</template>
            <template v-slot="scope">
              <el-form-item>
                <el-select v-model="scope.row.priceType" @change="handlePriceTypeSelect($event, scope.row, scope.$index)">
                  <el-option
                    v-for="item in ORDER_PRICE_TYPE_OPTIONS"
                    :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column v-if="!(order && order.termContract && order.termContract.termContractType === 'TIME_CHARTER')"/>
          <el-table-column v-if="!(order && order.termContract && order.termContract.termContractType === 'TIME_CHARTER')" type="expand" min-width="50px">
            <template v-slot="scope">
              <table-col-product-pricing parent='DialogVerifyOrder' :nominationDate="new Date(nominationDate)" :scopeRow="scope.row" :scopeIndex="scope.$index" priceProp="orderVerificationProductTableData" :priceType="scope.row.priceType" :priceMethodologyList="priceMethodologyList"
                :rulesReq="rules.reqRule" :notReqRule="rules.notReqRule" @handlePriceInfoChange="handlePriceInfoChange" @handleReferencePriceChange="handleReferencePriceChange"
                :holidayMap="holidayMap" :resetProductPriceWatcher="resetProductPriceWatcher"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <h1 class="margin-btm-0">Other Cost</h1>
      <div class="div-other-costs-body">
        <el-table v-loading="loading" :data="orderVerificationForm.orderVerificationOtherCostTableData" style="width: 50%" height="100%" @selection-change="handleOtherCostSelectionChange">
          <el-table-column key="checkbox" type="selection" min-width="55"/>
          <el-table-column prop="description" label="Description"/>
          <el-table-column label="Total Cost">
            <template v-slot="scope">
              <div>${{numberWithCommas(scope.row.cost, 3)}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="supplyDate" label="Supply Date">
            <template v-slot:header><span>* </span>Supply Date</template>
            <template v-slot="scope">
               <el-form-item :prop="`orderVerificationOtherCostTableData.${scope.$index}.supplyDate`" :rules="rules.reqRule"><el-date-picker
                  v-model="scope.row.supplyDate"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="currency" label="Currency">
            <template v-slot="scope">
              <el-select
                v-model="scope.row.currency"
                disabled
              >
                <el-option
                  v-for="item in currencyList"
                  :key="item.id" :label="item.code" :value="item.code">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-form>
    <div class="el-dialog__footer">
      <el-button
        outline
        round

        @click="handleVerifyOrderDialogClose"
      >
        Cancel
      </el-button>
      <el-button
        round  type="primary"
        @click="handleVerifyOrderDialogConfirm"
        :disabled="!(orderVerificationProductsSelected.length > 0 || orderVerificationOtherCostSelected.length > 0)"
      >
        Confirm
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { numberWithCommas, openBlobUrlInNewTab } from '@/utils/index.js'
import TableColProductPricing from './TableColProductPricing'
import { ORDER_PRICE_TYPE_OPTIONS, ORDER_TYPE } from '@/constants.js'
import { getOrderById } from '@/services/modules/order.js'
import { getFileBlobUrl } from '@/services/modules/comm.js'

export default {
  components: {
    TableColProductPricing
  },
  name: 'DialogVerifyOrder',
  props: {
    dialogVerifyOrder: Boolean,
    orderId: String,
    currencyList: Array,
    priceMethodologyList: Array,
    holidayMap: Map,
    nominationDate: Date,
    orderType: String
  },
  data () {
    const rules = {
      reqRule: [{ required: true, validator: this.reqCustomRule, trigger: ['blur', 'change'] }],
      commReqRule: [{ required: true, validator: this.reqCommCustomRule, trigger: ['blur', 'change'] }],
      notReqRule: [{ required: false }]
    }
    const productPrice = {
      priceRateType: null,
      priceRateTypeMetric: 'MID',
      priceMethodologyId: null,
      pricePeriod: null,
      priceStartDate: null,
      priceEndDate: null,
      pricePremium: 'FLAT',
      priceRemark: null,
      referencePrice: null,
      priceUnitMetric: 'MT',
      priceConversionRate: 1,
      isPriceFactorFirst: false
    }
    return {
      rules,
      order: null,
      loading: true,
      ORDER_TYPE,
      ORDER_PRICE_TYPE_OPTIONS: Object.freeze(ORDER_PRICE_TYPE_OPTIONS),
      orderVerificationForm: {
        orderVerificationProductTableData: [],
        orderVerificationOtherCostTableData: []
      },
      orderVerificationProductsSelected: [],
      orderVerificationOtherCostSelected: [],
      productPrice,
      resetProductPriceWatcher: false
    }
  },
  methods: {
    numberWithCommas,
    getFileBlobUrl,
    openBlobUrlInNewTab,
    previewFile (fileId) {
      this.getFileBlobUrl(fileId).then(url => {
        this.openBlobUrlInNewTab(url)
      }).catch(e => {

      })
    },
    handleSelectedJobsChange (selectedJobs, index) {
      let totalDeliveredQty = 0
      const p = this.orderVerificationForm.orderVerificationProductTableData[index]
      p.jobInfo.ebdnDates.splice(0, this.orderVerificationForm.orderVerificationProductTableData[index].jobInfo.ebdnDates.length)
      selectedJobs.forEach(j => {
        totalDeliveredQty = totalDeliveredQty + j.docQty

        if (j.docDate) {
          p.jobInfo.ebdnDates.push(j.docDate + ` (${j.docNumber} Alongside)`)
        }
        if (j.startPumpDate) {
          p.jobInfo.ebdnDates.push(j.startPumpDate + ` (${j.docNumber} Start Pump)`)
        }
        if (j.endPumpDate) {
          p.jobInfo.ebdnDates.push(j.endPumpDate + ` (${j.docNumber} End Pump)`)
        }
      })
      this.orderVerificationForm.orderVerificationProductTableData[index].verifiedQuantity = totalDeliveredQty
      this.orderVerificationForm.orderVerificationProductTableData[index].supplyDate = null
    },
    handlePriceTypeSelect (val, row, index) {
      if (val === 'FORMULA') {
        this.$refs.verifyProductTable.toggleRowExpansion(row, true)
      } else {
        this.$refs.verifyProductTable.toggleRowExpansion(row, false)
        for (const key in this.productPrice) {
          this.orderVerificationForm.orderVerificationProductTableData[index][key] = null
        }
        this.resetProductPriceWatcher = !this.resetProductPriceWatcher
      }
      this.orderVerificationForm.orderVerificationProductTableData[index].priceRateTypeMetric = 'MID'
      this.orderVerificationForm.orderVerificationProductTableData[index].priceUnitMetric = 'MT'
      this.orderVerificationForm.orderVerificationProductTableData[index].priceConversionRate = 1
    },
    handlePriceInfoChange (val, index, name, parent) {
      if (parent === 'DialogVerifyOrder') {
        this.orderVerificationForm.orderVerificationProductTableData[index][name] = val
      }
    },
    handleReferencePriceChange (val, index, parent) {
      if (parent === 'DialogVerifyOrder') {
        this.orderVerificationForm.orderVerificationProductTableData[index].referencePrice = val
      }
    },
    reqCustomRule (rule, value, callback) {
      const isProduct = rule.field.split('.')[0] === 'orderVerificationProductTableData'
      const i = rule.field.split('.')[1]

      let indexItem
      if (isProduct) {
        indexItem = this.orderVerificationProductsSelected.findIndex(ps => ps.id === this.orderVerificationForm.orderVerificationProductTableData[i].id)
      } else {
        indexItem = this.orderVerificationOtherCostSelected.findIndex(ps => ps.id === this.orderVerificationForm.orderVerificationOtherCostTableData[i].id)
      }
      if (indexItem !== -1 && !value) {
        callback(new Error('This field is required'))
      }
      callback()
    },
    handleProductSelectionChange (selection) {
      this.orderVerificationProductsSelected = selection
    },
    handleOtherCostSelectionChange (selection) {
      this.orderVerificationOtherCostSelected = selection
    },
    handleVerifyOrderDialogOpen () {
      this.loading = true
      getOrderById(this.orderId).then(res => {
        if (res?.code === 1000) {
          this.order = res.data
          this.populateJobs().then(() => {
            // expand row to calculate reference price
            this.orderVerificationForm.orderVerificationProductTableData.forEach((p, index) => {
              if (p.priceType === 'FORMULA') {
                this.$refs.verifyProductTable.toggleRowExpansion(this.orderVerificationForm.orderVerificationProductTableData[index], true)
              }
            })
          })
        }
      })
    },
    handleOrderFormValidation () {
      this.$refs.orderVerificationForm.clearValidate()
      const productFieldNames = ['jobInfo.selectedJobs', 'supplyDate', 'verifiedQuantity', 'finalBillingPrice', 'priceRateType', 'priceRateTypeMetric',
        'priceMethodologyId', 'pricePeriod', 'priceStartDate', 'priceEndDate', 'pricePremium']
      this.orderVerificationProductsSelected.map(ps => {
        const index = this.orderVerificationForm.orderVerificationProductTableData.findIndex(d => d.id === ps.id)
        if (index !== -1) {
          // expand row to validate
          if (this.orderVerificationForm.orderVerificationProductTableData[index].priceType === 'FORMULA') {
            this.$refs.verifyProductTable.toggleRowExpansion(this.orderVerificationForm.orderVerificationProductTableData[index], true)
          }
          productFieldNames.forEach(d => {
            this.$refs.orderVerificationForm.validateField(`orderVerificationProductTableData.${index}.${d}`)
          })
        }
      })
      const otherCostFieldNames = ['supplyDate']
      this.orderVerificationOtherCostSelected.map(ps => {
        const index = this.orderVerificationForm.orderVerificationOtherCostTableData.findIndex(d => d.id === ps.id)
        if (index !== -1) {
          otherCostFieldNames.forEach(d => {
            this.$refs.orderVerificationForm.validateField(`orderVerificationOtherCostTableData.${index}.${d}`)
          })
        }
      })
    },
    handleVerifyOrderDialogConfirm () {
      this.handleOrderFormValidation()
      if (this.$refs.orderVerificationForm.fields.find((f) => f.validateState === 'error')) {
        this.$message.error('Error. Required fields not filled in.')
        this.$emit('update:dialogVerifyOrder', true)
      } else {
        this.$emit('handleVerifyOrderDialogConfirm', this.order.id, this.orderVerificationProductsSelected, this.orderVerificationOtherCostSelected)
      }
    },
    handleVerifyOrderDialogClose () {
      this.$emit('handleVerifyOrderDialogClose')
      this.$refs.orderVerificationForm.resetFields()
      this.orderVerificationForm.orderVerificationProductTableData.forEach(row => {
        this.$refs.verifyProductTable.toggleRowExpansion(row, false)
      })
    },
    async populateJobs () {
      const getJobInfo = (url) => {
        return new Promise(resolve => {
          this.$request.get({
            url
          }).then(res => {
            if (res?.code === 1000) {
              const jobInfo = res.data
              resolve({ url, jobInfo })
            }
          })
        })
      }
      this.orderVerificationForm.orderVerificationProductTableData = this.order.products.filter(p => p.status === 'PENDING').map(p => {
        return {
          ...p,
          jobInfo: {
            jobOptions: [],
            selectedJobs: []
          }
        }
      })
      const reqUrls = new Set(this.orderVerificationForm.orderVerificationProductTableData.map(p => `${this.$apis.getBalanceNumber}${this.order.id}/productName/${p.sysProductId}/specs/${p.sysProductSpecificationId}/info`))
      const promises = []
      reqUrls.forEach(u => {
        promises.push(getJobInfo(u))
      })
      const jobInfoList = await Promise.all(promises)
      this.orderVerificationForm.orderVerificationProductTableData.forEach(p => {
        const url = `${this.$apis.getBalanceNumber}${this.order.id}/productName/${p.sysProductId}/specs/${p.sysProductSpecificationId}/info`
        const item = jobInfoList.find(j => j.url === url)
        // set to 0, BE does not reset verified qty when rejected from postedlist
        p.verifiedQuantity = 0
        item.jobInfo.forEach(d => {
          p.verifiedQuantity = p.verifiedQuantity + d.docQty
        })
        p.finalBillingPrice = p.price
        if (item.jobInfo && item.jobInfo.length > 0) {
          p.jobInfo.jobOptions = item.jobInfo
          p.jobInfo.selectedJobs = item.jobInfo
          p.jobInfo.ebdnDates = []
          p.jobInfo.selectedJobs.map(sj => {
            if (sj.docDate) {
              p.jobInfo.ebdnDates.push(sj.docDate + ` (${sj.docNumber} Alongside)`)
            }
            if (sj.startPumpDate) {
              p.jobInfo.ebdnDates.push(sj.startPumpDate + ` (${sj.docNumber} Start Pump)`)
            }
            if (sj.endPumpDate) {
              p.jobInfo.ebdnDates.push(sj.endPumpDate + ` (${sj.docNumber} End Pump)`)
            }
          })
          if (this.order.orderType === 'EX_WHARF') {
            p.supplyDate = item.jobInfo[0].docDate
          }
        }
      })
      this.orderVerificationForm.orderVerificationProductTableData = this.orderVerificationForm.orderVerificationProductTableData.filter(p => p.jobInfo.jobOptions.length > 0)
      this.orderVerificationForm.orderVerificationOtherCostTableData = this.order.otherCosts.filter(o => o.status === 'CONFIRMED')
      this.loading = false
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../index";
</style>
